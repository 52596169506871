export default {
	channelAuthorLabel: "Автор",
	channelCategoryLabel: "Категория",
	channelDescriptionLabel: "Баяны:",
	channelIdLabel: "Канал ID",
	channelLanguageLabel: "Тил",
	copiedLabel: "Көчүрүлгөн",
	downloadAppLabel: "TeleGuard тиркемесин жүктөп алыңыз",
	errorInternalError: "Биз серверден жооп ала алган жокпуз жана бул көйгөйдү чечип жатабыз. Кийинчерээк кирүүгө аракет кылып, өтүнүчтү кайра кайталаңыз",
	errorNotFound: "Көрсөтүлгөн ID менен каналды таба алган жокпуз. Киргизилген идентификатордун тууралыгын текшерип, кайра аракет кылыңыз",
	fieldMustBeRequired: "{0} талаасы толтурулушу керек",
	findChannelLabel: "Табуу",
	joinToLabel: "Каналга жазылыңыз жана көбүрөөк маалымат алыңыз",
	mustBeNoLens: "{0} талаасы {1} белгиден кем болбошу керек",
	mustBeWithoutSpaces: "{0} талаасы боштуксуз болушу керек",
	notFoundLabel: "Барак табылган жок...",
	postNotFoundLabel: "Пост табылган жок...",
	postsLabel: "билдирүүлөр",
	previewChannelLabel: "Каналды алдын ала көрүү",
	searchOnChannelLabel: "Каналдан издөө",
	subscribersLabel: "жазылуучулар",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "Каналга",
	viewOnlyAppLabel: "TeleGuardда гана көрүү"
}