export default {
	channelAuthorLabel: "オーサー",
	channelCategoryLabel: "カテゴリー",
	channelDescriptionLabel: "商品説明",
	channelIdLabel: "チャンネルID",
	channelLanguageLabel: "言語",
	copiedLabel: "コピー機",
	downloadAppLabel: "TeleGuardアプリのダウンロード",
	errorInternalError: "サーバーから応答が得られず、すでに対処しています。後ほどログインして、再度リクエストをお試しください。",
	errorNotFound: "指定されたIDを持つチャンネルが見つかりませんでした。入力されたIDが正しいかどうか確認し、もう一度試してください。",
	fieldMustBeRequired: "{0} フィールドを埋める必要があります。",
	findChannelLabel: "探す",
	joinToLabel: "チャンネル登録と詳細",
	mustBeNoLens: "フィールド{0}は{1}文字以下であってはならない。",
	mustBeWithoutSpaces: "フィールド{0}にはスペースを入れないこと",
	notFoundLabel: "ページが見つかりません...",
	postNotFoundLabel: "投稿が見つかりません...",
	postsLabel: "ポスト",
	previewChannelLabel: "プレビューチャンネル",
	searchOnChannelLabel: "チャンネルで検索",
	subscribersLabel: "契約者",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "チャンネルへ",
	viewOnlyAppLabel: "TeleGuardのみでの表示"
}