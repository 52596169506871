
import { openDB } from "idb";


const cachedFilesSettings = {
    dbName: "cachedFiles",
    dbVersion: 1,
    tables: {
        files: "files"
    }
};
const tWebVSettings = {
    dbName: "twebv",
    dbVersion: 1,
    tables: {
        keyval: "keyval"
    }
};


const dbCachcedFilesPromise = openDB(cachedFilesSettings.dbName, cachedFilesSettings.dbVersion, {
    upgrade(db) {
        Object.values(cachedFilesSettings.tables)
            .map(table => {
                db.createObjectStore(table);
            });
    },
});

const dbTWebVPromise = openDB(tWebVSettings.dbName, tWebVSettings.dbVersion, {
    upgrade(db) {
        Object.values(tWebVSettings.tables).map(table => {
            db.createObjectStore(table);
        });
    },
});

async function getFromCachedFiles(table, key) {
    return (await dbCachcedFilesPromise).get(table, key);
}

async function setToCachedFiles(table, key, val) {
    return (await dbCachcedFilesPromise).put(table, val, key);
}

async function getFromTWebV(table, key) {
    return (await dbTWebVPromise).get(table, key);
}

async function setToTWebV(table, key, val) {
    return (await dbTWebVPromise).put(table, val, key);
}

export { getFromCachedFiles, setToCachedFiles, getFromTWebV, setToTWebV, cachedFilesSettings, tWebVSettings }