export default {
	channelAuthorLabel: "Auteur",
	channelCategoryLabel: "Categorie",
	channelDescriptionLabel: "Beschrijving",
	channelIdLabel: "Kanaal ID",
	channelLanguageLabel: "Taal",
	copiedLabel: "Gekopieerd",
	downloadAppLabel: "Download TeleGuard-app",
	errorInternalError: "We konden geen antwoord krijgen van de server en zijn al bezig met dit probleem. Probeer later in te loggen en herhaal het verzoek opnieuw",
	errorNotFound: "We konden het kanaal met de gespecificeerde ID niet vinden. Controleer de juistheid van de ingevoerde ID en probeer opnieuw",
	fieldMustBeRequired: "Het {0} veld moet gevuld zijn",
	findChannelLabel: "Zoek",
	joinToLabel: "Abonneer je op het kanaal en kom meer te weten",
	mustBeNoLens: "Veld {0} mag niet kleiner zijn dan {1} tekens",
	mustBeWithoutSpaces: "Veld {0} moet zonder spaties zijn",
	notFoundLabel: "Pagina niet gevonden...",
	postNotFoundLabel: "Post niet gevonden...",
	postsLabel: "berichten",
	previewChannelLabel: "Voorbeeld kanaal",
	searchOnChannelLabel: "Zoek in kanaal",
	subscribersLabel: "abonnees",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "Naar het kanaal",
	viewOnlyAppLabel: "Alleen bekijken in TeleGuard"
}