export default {
	channelAuthorLabel: "Autore",
	channelCategoryLabel: "Categoria",
	channelDescriptionLabel: "Descrizione",
	channelIdLabel: "ID canale",
	channelLanguageLabel: "Lingua",
	copiedLabel: "Copiato",
	downloadAppLabel: "Scarica l'applicazione TeleGuard",
	errorInternalError: "Non siamo riusciti a ottenere una risposta dal server e ci stiamo già occupando del problema. Provi ad accedere più tardi e a ripetere la richiesta.",
	errorNotFound: "Non è stato possibile trovare il canale con l'ID specificato. Verificare la correttezza dell'ID inserito e riprovare.",
	fieldMustBeRequired: "Il campo {0} deve essere riempito",
	findChannelLabel: "Trova",
	joinToLabel: "Iscriviti al canale per saperne di più",
	mustBeNoLens: "Il campo {0} non deve essere inferiore a {1} caratteri",
	mustBeWithoutSpaces: "Il campo {0} deve essere senza spazi",
	notFoundLabel: "Pagina non trovata...",
	postNotFoundLabel: "Post non trovato...",
	postsLabel: "posti",
	previewChannelLabel: "Canale di anteprima",
	searchOnChannelLabel: "Ricerca nel canale",
	subscribersLabel: "abbonati",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "Al canale",
	viewOnlyAppLabel: "Visualizzazione solo in TeleGuard"
}