import { ACCESS_TOKEN } from "@/utils/constants";
import { setToTWebV, getFromTWebV, tWebVSettings } from "@/backend/indexedDB";

export function getUrls(text) {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/ig;
    let urls = [];
    text.replace(urlRegex, (url) => {
        urls.push(url);
    });
    return urls;
}

export const parseToken = function (_token) {
    return JSON.parse(Buffer.from(_token.split(".")[1], "base64"));
}

export function saveToken(payload) {
    setToTWebV(tWebVSettings.tables.keyval,ACCESS_TOKEN, payload.token);
}

export async function isAuth() {
    return await getFromTWebV(tWebVSettings.tables.keyval, ACCESS_TOKEN) != undefined;
}

export function modifyMediaField(jsonStr) {
    let modifiedMedia = [];
    try {
        let parsedJson = JSON.parse(jsonStr);
        Object.keys(parsedJson).map(mediaId => {
            let mediaFileInfo = parsedJson[mediaId];
            mediaFileInfo.id = mediaId;
            mediaFileInfo.preview = "";
            mediaFileInfo.fileBase64 = "";
            mediaFileInfo.isLoading = false;
            modifiedMedia.push(mediaFileInfo);
        });
    } catch (err) {
        //
    }

    return modifiedMedia;
}