import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { ru, en, ar, bg, cs, de, es, fa, fr, he, hu, hy, it, ja, ko, ky, lv, nl, pl, pt, sv, tr, uk, zh } from '@/locales'

Vue.use(Vuetify);

const colors = {
    primary: "#b6406c",
    secondary: "#FF8412",
    note: "#949494",
    lightgrey: "#D6D6D6",
    sublightgrey: "#EAEAEA",

}

export default new Vuetify({
    lang: {
        locales: { ru, en, ar, bg, cs, de, es, fa, fr, he, hu, hy, it, ja, ko, ky, lv, nl, pl, pt, sv, tr, uk, zh },
        current: 'en'
    },
    theme: {
        themes: {
            light: colors,
            dark: colors
        }
    },
    icons: {
        iconfont: 'mdi',
    },
});
