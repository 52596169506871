import AppApi from "@/backend/api";
import { parseToken, saveToken } from "@/utils/functions"

class AuthService {

    async auth() {
        return new Promise((resp, rej) => {

            AppApi.logIn()
                .then((data) => {
                    const token =
                        data.data.accessToken !== undefined ? data.data.accessToken : "";

                    const tokenData = parseToken(token);
                    const loginData = {
                        token: token,
                        tokenData: tokenData,
                    };
                    saveToken(loginData);
                    resp(loginData);
                })
                .catch((error) => {
                    rej(error)
                });
        });

    }

}

export default new AuthService