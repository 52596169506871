<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <div class="d-none">v.{{version}}</div>
  </v-app>
</template>
<script>
export default {
  name:"App",
  mounted: async function () {
    const navLang = navigator.language.split("-");
    const userLocale = navLang[0];
    const currentLang = userLocale ? userLocale : "en";
    this.$vuetify.lang.current = currentLang;
  },
  data:function(){
    return{
      version: process.env.VUE_APP_VERSION
    }
  }
}
</script>