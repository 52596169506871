import AppApi, { StatusResponse } from "@/backend/api";
import { setToCachedFiles, getFromCachedFiles, cachedFilesSettings } from "@/backend/indexedDB";

class DataService {
    async addToMediaCache(_key, _val) {
        return new Promise((resp, rej) => {
            try {
                setToCachedFiles(cachedFilesSettings.tables.files, _key, _val);
                resp("success");
            } catch (error) {
                rej(error);
            }
        });
    }
    async loadAvatarImage(_avatarRefId) {
        return new Promise((resp, rej) => {
            AppApi.getAvatar(_avatarRefId)
                .then((data) => {
                    resp(data);
                })
                .catch((error) => {
                    rej(error)
                });
        });
    }
    async getPosts(_channelId) {
        return new Promise((resp, rej) => {
            AppApi.getPosts(_channelId)
                .then((data) => {
                    resp(data);
                })
                .catch((error) => {
                    rej(error)
                });
        });
    }
    async getPostById(_postId) {
        return new Promise((resp, rej) => {
            AppApi.getPostById(_postId)
                .then((data) => {
                    resp(data);
                })
                .catch((error) => {
                    rej(error)
                });
        });
    }
    async searchChannel(_channelId) {
        return new Promise((resp, rej) => {
            AppApi.searchChannel(_channelId)
                .then((data) => {
                    resp(data);
                })
                .catch((error) => {
                    rej(error);
                });
        })
    }
    async getThumbnailMediaFile(_fileId) {
        return new Promise((resp, rej) => {
            const previewId = "preview-"+_fileId;
            getFromCachedFiles(cachedFilesSettings.tables.files,previewId).then((cachedData) => {
                if (cachedData === undefined) {
                    AppApi.getThumbnailMediaFile(_fileId)
                        .then((data) => {
                            if (data.responseStatus == StatusResponse.OK) {
                                this.addToMediaCache(previewId, data.data);
                            }
                            resp(data);
                        })
                        .catch((error) => {
                            rej(error);
                        });
                } else {
                    resp({ responseStatus: StatusResponse.OK, data: cachedData });
                }
            });
        })
    }
    async getMediaFile(_fileId) {
        return new Promise((resp, rej) => {
            getFromCachedFiles(cachedFilesSettings.tables.files,_fileId).then((cachedData) => {
                if (cachedData === undefined) {
                    AppApi.getMediaFile(_fileId)
                        .then(async (data) => {
                            if (data.responseStatus == StatusResponse.OK) {
                                this.addToMediaCache(_fileId, data.data);
                            }
                            resp(data);
                        })
                        .catch((error) => {
                            rej(error);
                        });
                } else {
                    resp({ responseStatus: StatusResponse.OK, data: cachedData });
                }
            });
        })
    }
}


export default new DataService