export default {
	channelAuthorLabel: "Autor",
	channelCategoryLabel: "Kategorie",
	channelDescriptionLabel: "Popis",
	channelIdLabel: "ID kanálu",
	channelLanguageLabel: "Jazyk",
	copiedLabel: "Zkopírováno",
	downloadAppLabel: "Stáhnout aplikaci TeleGuard",
	errorInternalError: "Nemohli jsme získat odpověď ze serveru a tento problém již řešíme. Zkuste se přihlásit později a zopakujte požadavek znovu.",
	errorNotFound: "Kanál se zadaným ID se nám nepodařilo najít. Zkontrolujte správnost zadaného ID a zkuste to znovu.",
	fieldMustBeRequired: "Pole {0} musí být vyplněno",
	findChannelLabel: "Najít",
	joinToLabel: "Přihlaste se k odběru kanálu a zjistěte více",
	mustBeNoLens: "Pole {0} nesmí být menší než {1} znaků",
	mustBeWithoutSpaces: "Pole {0} musí být bez mezer",
	notFoundLabel: "Stránka nebyla nalezena...",
	postNotFoundLabel: "Příspěvek nenalezen...",
	postsLabel: "příspěvky",
	previewChannelLabel: "Náhled kanálu",
	searchOnChannelLabel: "Vyhledávání v kanálu",
	subscribersLabel: "předplatitelé",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "Přejít na kanál",
	viewOnlyAppLabel: "Zobrazení pouze v aplikaci TeleGuard"
}