export default {
	channelAuthorLabel: "مؤلف",
	channelCategoryLabel: "فئة",
	channelDescriptionLabel: "وصف",
	channelIdLabel: "معرف القناة",
	channelLanguageLabel: "اللغة",
	copiedLabel: "نسخ",
	downloadAppLabel: "قم بتنزيل تطبيق TeleGuard",
	errorInternalError: "لم نتمكن من الحصول على رد من الخادم ونتعامل بالفعل مع هذه المشكلة. حاول تسجيل الدخول لاحقًا وكرر الطلب مرة أخرى",
	errorNotFound: "لم نتمكن من العثور على القناة بالمعرف المحدد. تحقق من صحة المعرف الذي تم إدخاله وحاول مرة أخرى",
	fieldMustBeRequired: "يجب ملء الحقل {0}",
	findChannelLabel: "تجد",
	joinToLabel: "اشترك في القناة وتعرف على المزيد",
	mustBeNoLens: "يجب ألا يقل عدد الأحرف في الحقل {0} عن {1} حرفًا",
	mustBeWithoutSpaces: "يجب أن يكون الحقل {0} بدون مسافات",
	notFoundLabel: "الصفحة غير موجودة...",
	postNotFoundLabel: "لم يتم العثور على المشاركة ...",
	postsLabel: "المشاركات",
	previewChannelLabel: "معاينة القناة",
	searchOnChannelLabel: "ابحث في القناة",
	subscribersLabel: "مشتركين",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "إلى القناة",
	viewOnlyAppLabel: "العرض فقط في TeleGuard"
}