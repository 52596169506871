import moment from 'moment';
import Vue from 'vue';

Vue.filter('wordAvatar', function (_value) {

    if (_value == undefined || _value.length === 0) return "";

    const _valueArr = _value.split(" ");
    const regexExp = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/gi;
    const isEmodji = regexExp.test(_value);

    return isEmodji ?
        _valueArr[0] :
        _valueArr[0].substring(0, 1).toUpperCase() + (_valueArr[1] !== undefined ? _valueArr[1].substring(0, 1).toUpperCase() : "");
});

Vue.filter('numberFormat', function (_number, args) {

    const toFixedFix = function (n, prec) {
        const k = Math.pow(10, prec);
        return '' + (Math.round(n * k) / k).toFixed(prec);
    }

    let number = _number.toString();
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    const n = !isFinite(+number) ? 0 : +number;
    const prec = !isFinite(+Number(args[0])) ? 0 : Math.abs(Number(args[0]));
    const sep = (typeof args[1] === 'undefined') ? ',' : args[1];
    const dec = (typeof args[2] === 'undefined') ? '.' : args[2];
    let s = [];

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec)
});

Vue.filter('dateFormat', function (_value, format) {   
    return _value !== null ? moment.utc(_value).local().format(format) : "";
});
