export default {
	channelAuthorLabel: "Autors",
	channelCategoryLabel: "Kategorija",
	channelDescriptionLabel: "Leírás",
	channelIdLabel: "Kanāla ID",
	channelLanguageLabel: "Valoda",
	copiedLabel: "Kopēts",
	downloadAppLabel: "Lejupielādēt lietotni TeleGuard",
	errorInternalError: "Mēs nevarējām saņemt atbildi no servera un jau risinām šo problēmu. Mēģiniet pieteikties vēlāk un atkārtojiet pieprasījumu vēlreiz.",
	errorNotFound: "Mēs nevarējām atrast kanālu ar norādīto ID. Pārbaudiet ievadītā ID pareizību un mēģiniet vēlreiz.",
	fieldMustBeRequired: "Jāaizpilda lauks {0}",
	findChannelLabel: "Atrast",
	joinToLabel: "Abonēt kanālu un uzzināt vairāk",
	mustBeNoLens: "Lauka {0} garums nedrīkst būt mazāks par {1} rakstzīmēm.",
	mustBeWithoutSpaces: "Laukā {0} jābūt bez atstarpēm",
	notFoundLabel: "Lapa nav atrasta...",
	postNotFoundLabel: "Ziņa nav atrasta...",
	postsLabel: "ziņojumi",
	previewChannelLabel: "Priekšskatījuma kanāls",
	searchOnChannelLabel: "Meklēt kanālā",
	subscribersLabel: "abonenti",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "Uz kanālu",
	viewOnlyAppLabel: "Skatīšana tikai programmā TeleGuard"
}