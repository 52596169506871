export default {
	channelAuthorLabel: "Автор",
	channelCategoryLabel: "Категория",
	channelDescriptionLabel: "Описание",
	channelIdLabel: "ID канала",
	channelLanguageLabel: "Язык",
	copiedLabel: "Скопировано",
	downloadAppLabel: "Скачать TeleGuard",
	errorInternalError: "Мы не смогли получить ответ от сервера и уже занимаемся этой проблемой. Попробуйте зайти позже и повторить запрос снова",
	errorNotFound: "Мы не смогли найти канал с указанным ID. Проверьте правильность введенного ID и попробуйте снова",
	fieldMustBeRequired: "Поле {0} должно быть заполнено",
	findChannelLabel: "Найти",
	joinToLabel: "присоединиться и узнать больше",
	mustBeNoLens: "Поле {0} не должно быть менее чем {1} символа",
	mustBeWithoutSpaces: "Поле {0} не должно содержать пробелов",
	notFoundLabel: "Страница не найдена...",
	postNotFoundLabel: "Пост не найден...",
	postsLabel: "постов",
	previewChannelLabel: "Посмотреть канал",
	searchOnChannelLabel: "Поиск по каналу",
	subscribersLabel: "подписчиков",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "К каналу",
	viewOnlyAppLabel: "Просмотр только в TeleGuard"
}