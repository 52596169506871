export default {
	channelAuthorLabel: "Auteur",
	channelCategoryLabel: "Catégorie",
	channelDescriptionLabel: "Description",
	channelIdLabel: "ID du canal",
	channelLanguageLabel: "Langue",
	copiedLabel: "Copié",
	downloadAppLabel: "Télécharger l'application TeleGuard",
	errorInternalError: "Nous n'avons pas pu obtenir de réponse du serveur et sommes déjà en train de traiter ce problème. Essayez de vous connecter plus tard et répétez la demande à nouveau.",
	errorNotFound: "Nous n'avons pas pu trouver la chaîne avec l'ID spécifié. Vérifiez l'exactitude de l'ID saisi et réessayez.",
	fieldMustBeRequired: "Le champ {0} doit être rempli",
	findChannelLabel: "Trouvez",
	joinToLabel: "Abonnez-vous à la chaîne et apprenez-en davantage",
	mustBeNoLens: "Le champ {0} ne doit pas être inférieur à {1} caractères.",
	mustBeWithoutSpaces: "Le champ {0} doit être sans espace",
	notFoundLabel: "Page non trouvée...",
	postNotFoundLabel: "Poste introuvable...",
	postsLabel: "postes",
	previewChannelLabel: "Canal de prévisualisation",
	searchOnChannelLabel: "Recherche dans le canal",
	subscribersLabel: "abonnés",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "Vers le canal",
	viewOnlyAppLabel: "Visualisation uniquement dans TeleGuard"
}