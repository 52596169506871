export default {
	channelAuthorLabel: "מְחַבֵּר",
	channelCategoryLabel: "קטגוריה",
	channelDescriptionLabel: "תיאור",
	channelIdLabel: "チャンネルID",
	channelLanguageLabel: "שפה",
	copiedLabel: "מוּעֲתָק",
	downloadAppLabel: "הורד את אפליקציית TeleGuard",
	errorInternalError: "לא הצלחנו לקבל תגובה מהשרת וכבר אנו מטפלים בבעיה זו. נסה להיכנס מאוחר יותר וחזור על הבקשה שוב",
	errorNotFound: "לא הצלחנו למצוא את הערוץ עם המזהה שצוין. בדוק את נכונות המזהה שהוזן ונסה שוב",
	fieldMustBeRequired: "יש למלא את השדה {0}",
	findChannelLabel: "למצוא",
	joinToLabel: "הירשם לערוץ ולמד עוד",
	mustBeNoLens: "שדה {0} לא יכול להיות פחות מ-{1} תווים",
	mustBeWithoutSpaces: "שדה {0} חייב להיות ללא רווחים",
	notFoundLabel: "הדף לא נמצא...",
	postNotFoundLabel: "הפוסט לא נמצא...",
	postsLabel: "פוסטים",
	previewChannelLabel: "ערוץ תצוגה מקדימה",
	searchOnChannelLabel: "חפש בערוץ",
	subscribersLabel: "מנויים",
	tgIdLabel: "מזהה TeleGuard",
	tgLabel: "TeleGuard",
	toChannelLabel: "לערוץ",
	viewOnlyAppLabel: "צפייה רק ב-TeleGuard"
}