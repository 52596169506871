import { ACCESS_TOKEN } from "@/utils/constants";
import axios from "axios";
import AuthService from "@/backend/services/authService";
import { getFromTWebV, tWebVSettings } from "@/backend/indexedDB";

export const StatusResponse = {
    OK: 20000,
    BAD_REQUEST: 40000,
    NOT_FOUND: 40400,
    FORBIDDEN: 40300,
    INTERNAL_ERROR: 50000
}

class AppApi {
    http;
    requestTimeout = 30000;
    CLIENT_NETWORK_ERROR = 499;
    BAD_AUTH_ERROR = 401;
    BAD_AUTH_REQUEST_COUNT_MAX = 3;
    badAuthRequestCountNow = 0;

    noAuthURI = ["/auth"];

    user = (process.env && process.env.VUE_APP_API_USER) || "";
    password = (process.env && process.env.VUE_APP_API_PASSWORD) || "";

    constructor(_baseUrl) {
        this.http = axios.create({
            baseURL: _baseUrl,
            timeout: this.requestTimeout,
            withCredentials: false,
        });
        this.useRequestInterceptor();
        this.useResponseInterceptor();
    }

    useRequestInterceptor() {
        this.http.interceptors.request.use(
            async (config) => {
                const { url = "", headers } = config;

                if (!this.noAuthURI.includes(url)) {
                    const accessToken = await getFromTWebV(tWebVSettings.tables.keyval, ACCESS_TOKEN);
                    const authToken = `Bearer ${accessToken}`;
                    headers["Authorization"] = authToken;
                }
                return config;
            },
            error => Promise.reject(error)
        );
    }

    useResponseInterceptor() {
        this.http.interceptors.response.use(
            response => response,
            async (reason) => {
                const status = reason.response ? reason.response.status : null

                if (status === this.BAD_AUTH_ERROR) {
                    this.badAuthRequestCountNow++;
                    if (this.badAuthRequestCountNow < this.BAD_AUTH_REQUEST_COUNT_MAX) {
                        await AuthService.auth();
                        return this.http.request(reason.config);
                    }
                }
                return Promise.reject(reason);
            }
        );
    }

    onReject(reason) {
        let code = reason.response.status;
        const message = reason.message;
        const data = reason.response.data;

        if (message === "Network Error")
            code = 499;

        return { code: code, message: message, data: data };
    }

    async handleAxiosResponse(request) {
        try {
            return await request.data;
        } catch (reason) {
            return Promise.reject(this.onReject(reason));
        }
    }

    async logIn() {
        try {
            return this.handleAxiosResponse(await this.http.post("/auth", { username: this.user, password: this.password }));
        } catch (reason) {
            return Promise.reject(this.onReject(reason));
        }
    }
    async searchChannel(channelId) {
        try {
            return this.handleAxiosResponse(await this.http.get(`/channels/${channelId}/search`));
        } catch (reason) {
            return Promise.reject(this.onReject(reason));
        }
    }

    async getAvatar(avatarId) {
        try {
            return this.handleAxiosResponse(await this.http.get(`/common/avatar/${avatarId}`));
        } catch (reason) {
            return Promise.reject(this.onReject(reason));
        }
    }

    async getPosts(channelId) {
        try {
            return this.handleAxiosResponse(await this.http.get(`/channels/${channelId}/posts`));
        } catch (reason) {
            return Promise.reject(this.onReject(reason));
        }
    }
    
    async getPostById(postId) {
        try {
            return this.handleAxiosResponse(await this.http.get(`/channels/posts/${postId}`));
        } catch (reason) {
            return Promise.reject(this.onReject(reason));
        }
    }
    
    async getMediaFile(fileId) {
        try {
            return this.handleAxiosResponse(await this.http.get(`/common/media/file/${fileId}`));
        } catch (reason) {
            return Promise.reject(this.onReject(reason));
        }
    }
    async getThumbnailMediaFile(fileId) {
        try {
            return this.handleAxiosResponse(await this.http.get(`/common/media/file/thumbnail/${fileId}`));
        } catch (reason) {
            return Promise.reject(this.onReject(reason));
        }
    }
    async getLinkPreview(_url) {
        try {
            return this.handleAxiosResponse(await this.http.post(`/common/link-preview`, {url: _url}));
        } catch (reason) {
            return Promise.reject(this.onReject(reason));
        }
    }
}

export const baseUrl = (process.env && process.env.VUE_APP_API_URL) || "";

const appApi = new AppApi(baseUrl);

export default appApi;