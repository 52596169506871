export default {
	channelAuthorLabel: "저자",
	channelCategoryLabel: "범주",
	channelDescriptionLabel: "설명",
	channelIdLabel: "채널 ID",
	channelLanguageLabel: "언어",
	copiedLabel: "복사됨",
	downloadAppLabel: "텔레가드 앱 다운로드",
	errorInternalError: "서버로부터 응답을 받을 수 없으며 이미 이 문제를 처리하고 있습니다. 나중에 로그인을 시도하고 요청을 다시 반복하십시오.",
	errorNotFound: "지정된 ID의 채널을 찾을 수 없습니다. 입력한 아이디가 맞는지 확인 후 재시도",
	fieldMustBeRequired: "{0} 필드를 채워야 합니다.",
	findChannelLabel: "찾다",
	joinToLabel: "채널을 구독하고 자세히 알아보기",
	mustBeNoLens: "{0} 필드는 {1}자 이상이어야 합니다.",
	mustBeWithoutSpaces: "{0} 필드에는 공백이 없어야 합니다.",
	notFoundLabel: "페이지를 찾을 수 없습니다...",
	postNotFoundLabel: "게시물을 찾을 수 없습니다...",
	postsLabel: "게시물",
	previewChannelLabel: "미리보기 채널",
	searchOnChannelLabel: "채널에서 검색",
	subscribersLabel: "구독자",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "채널로 이동",
	viewOnlyAppLabel: "TeleGuard에서만 보기"
}