export default {
	channelAuthorLabel: "Autor",
	channelCategoryLabel: "Kategorie",
	channelDescriptionLabel: "Beschreibung",
	channelIdLabel: "Channel ID",
	channelLanguageLabel: "Sprache",
	copiedLabel: "Kopiert",
	downloadAppLabel: "TeleGuard-App herunterladen",
	errorInternalError: "Wir konnten vom Server keine Antwort erhalten und beschäftigen uns bereits mit diesem Problem. Versuchen Sie, sich später anzumelden, und wiederholen Sie die Anfrage erneut",
	errorNotFound: "Wir konnten den Channel mit der angegebenen ID nicht finden. Prüfen Sie die Eingaben und versuchen Sie es erneut",
	fieldMustBeRequired: "Das Feld {0} muss ausgefüllt sein",
	findChannelLabel: "Finden",
	joinToLabel: "Den Channel abonnieren und mehr erfahren",
	mustBeNoLens: "Das Feld {0} darf nicht weniger als {1} Zeichen enthalten",
	mustBeWithoutSpaces: "Das Feld {0} darf keine Leerzeichen enthalten",
	notFoundLabel: "Seite nicht gefunden...",
	postNotFoundLabel: "Beitrag nicht gefunden...",
	postsLabel: "Beiträge",
	previewChannelLabel: "Channel Vorschau",
	searchOnChannelLabel: "Suche im Channel",
	subscribersLabel: "Abonnenten",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "Zum Channel",
	viewOnlyAppLabel: "Anschauen nur in TeleGuard"
}