export const ACCESS_TOKEN = '_token';

export const IMAGE_BASE64_PREFIX = "data:image/jpeg;base64";
export const VIDEO_BASE64_PREFIX = "data:video/mp4;base64";

export const CHANNEL_AVATAR_SIZE = 180;//px
export const CHANNEL_AVATAR_FONT_SIZE = 48;//px

export const MIN_LENGTH = 4;

export const ANDROID_APP_LINK = "https://play.google.com/store/apps/details?id=ch.swisscows.messenger.teleguardapp";
export const IOS_APP_LINK = "https://apps.apple.com/us/app/teleguard/id1505636751";
export const WEB_APP_LINK = "https://teleguard.com";

export const MEDIA_FILE_TYPE = {
    VIDEO: "VIDEO",
    IMAGE: "IMAGE"
};

export const ON_PAGE_COUNT_POSTS = 20;
