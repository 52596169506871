export default {
	channelAuthorLabel: "作者",
	channelCategoryLabel: "类别",
	channelDescriptionLabel: "描述",
	channelIdLabel: "频道 ID",
	channelLanguageLabel: "语言",
	copiedLabel: "已复制",
	downloadAppLabel: "下载 TeleGuard 应用程序",
	errorInternalError: "我们无法从服务器获得响应，并且已经在处理这个问题。 稍后尝试登录并再次重复请求",
	errorNotFound: "我们找不到具有指定 ID 的频道。 检查输入ID的正确性，然后重试",
	fieldMustBeRequired: "{0} 字段必须填写",
	findChannelLabel: "寻找",
	joinToLabel: "订阅频道并了解更多信息",
	mustBeNoLens: "字段 {0} 不得少于 {1} 个字符",
	mustBeWithoutSpaces: "字段 {0} 必须不含空格",
	notFoundLabel: "网页未找到...",
	postNotFoundLabel: "帖子没找到...",
	postsLabel: "帖子",
	previewChannelLabel: "预览频道",
	searchOnChannelLabel: "在频道中搜索",
	subscribersLabel: "订户",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "前往频道",
	viewOnlyAppLabel: "仅在 TeleGuard 中查看"
}