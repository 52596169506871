export default {
	channelAuthorLabel: "Autor",
	channelCategoryLabel: "Categoría",
	channelDescriptionLabel: "Descripción",
	channelIdLabel: "ID del canal",
	channelLanguageLabel: "Idioma",
	copiedLabel: "Copiado",
	downloadAppLabel: "Descargar la aplicación TeleGuard",
	errorInternalError: "No hemos podido obtener una respuesta del servidor y ya estamos tratando este problema. Intente conectarse más tarde y repita la solicitud de nuevo",
	errorNotFound: "No hemos podido encontrar el canal con el ID especificado. Compruebe que el ID introducido es correcto y vuelva a intentarlo",
	fieldMustBeRequired: "El campo {0} debe ser rellenado",
	findChannelLabel: "Encuentre",
	joinToLabel: "Suscríbase al canal y obtenga más información",
	mustBeNoLens: "El campo {0} no debe ser inferior a {1} caracteres",
	mustBeWithoutSpaces: "El campo {0} debe estar sin espacios",
	notFoundLabel: "Página no encontrada...",
	postNotFoundLabel: "Publicación no encontrada...",
	postsLabel: "puestos",
	previewChannelLabel: "Vista previa del canal",
	searchOnChannelLabel: "Buscar en el canal",
	subscribersLabel: "suscriptores",
	tgIdLabel: "Identificador TeleGuard",
	tgLabel: "TeleGuard",
	toChannelLabel: "Ir a canal",
	viewOnlyAppLabel: "Visualización sólo en TeleGuard"
}