export default {
	channelAuthorLabel: "Autor",
	channelCategoryLabel: "Kategoria",
	channelDescriptionLabel: "Opis",
	channelIdLabel: "Identyfikator kanału",
	channelLanguageLabel: "Język",
	copiedLabel: "Skopiowane",
	downloadAppLabel: "Pobierz aplikację TeleGuard",
	errorInternalError: "Nie mogliśmy uzyskać odpowiedzi z serwera i już zajmujemy się tym problemem. Spróbuj zalogować się później i powtórz żądanie ponownie",
	errorNotFound: "Nie udało się znaleźć kanału o podanym ID. Sprawdź poprawność wprowadzonego ID i spróbuj ponownie.",
	fieldMustBeRequired: "Pole {0} musi być wypełnione",
	findChannelLabel: "Znajdź",
	joinToLabel: "Subskrybuj kanał i dowiedz się więcej",
	mustBeNoLens: "Pole {0} nie może być mniejsze niż {1} znaków",
	mustBeWithoutSpaces: "Pole {0} musi być bez spacji",
	notFoundLabel: "Nie znaleziono strony...",
	postNotFoundLabel: "Nie znaleziono posta…",
	postsLabel: "stanowiska",
	previewChannelLabel: "Kanał podglądu",
	searchOnChannelLabel: "Szukaj w kanale",
	subscribersLabel: "abonenci",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "Do kanału",
	viewOnlyAppLabel: "Podgląd tylko w TeleGuard"
}