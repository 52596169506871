export default {
	channelAuthorLabel: "Yazar",
	channelCategoryLabel: "Kategori",
	channelDescriptionLabel: "Açıklama",
	channelIdLabel: "Kanal Kimliği",
	channelLanguageLabel: "Dil",
	copiedLabel: "Kopyalandı",
	downloadAppLabel: "TeleGuard uygulamasını indirin",
	errorInternalError: "Sunucudan yanıt alamadık ve halihazırda bu sorunla ilgileniyoruz. Daha sonra giriş yapmayı deneyin ve isteği tekrarlayın",
	errorNotFound: "Belirtilen ID'ye sahip kanalı bulamadık. Girilen ID'nin doğruluğunu kontrol edin ve tekrar deneyin",
	fieldMustBeRequired: "{0} alanı doldurulmalıdır",
	findChannelLabel: "Bul",
	joinToLabel: "Kanala abone olun ve daha fazlasını öğrenin",
	mustBeNoLens: "Alan {0}, {1} karakterden az olmamalıdır",
	mustBeWithoutSpaces: "Alan {0} boşluksuz olmalıdır",
	notFoundLabel: "Sayfa bulunamadı...",
	postNotFoundLabel: "Yazı bulunamadı...",
	postsLabel: "gönderiler",
	previewChannelLabel: "Önizleme kanalı",
	searchOnChannelLabel: "Kanal içinde arama",
	subscribersLabel: "aboneler",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "Kanala",
	viewOnlyAppLabel: "Sadece TeleGuard'da görüntüleme"
}