export default {
	channelAuthorLabel: "Հեղինակ",
	channelCategoryLabel: "Կատեգորիա",
	channelDescriptionLabel: "Նկարագրություն",
	channelIdLabel: "Ալիքի ID",
	channelLanguageLabel: "Լեզու",
	copiedLabel: "Պատճենվել է",
	downloadAppLabel: "Ներբեռնեք TeleGuard հավելվածը",
	errorInternalError: "Մենք չկարողացանք պատասխան ստանալ սերվերից և արդեն զբաղվում ենք այս խնդրի հետ: Փորձեք ավելի ուշ մուտք գործել և նորից կրկնել հարցումը",
	errorNotFound: "Մենք չկարողացանք գտնել նշված ID-ով ալիքը: Ստուգեք մուտքագրված ID-ի ճիշտությունը և նորից փորձեք",
	fieldMustBeRequired: "{0} դաշտը պետք է լրացվի",
	findChannelLabel: "Գտեք",
	joinToLabel: "Բաժանորդագրվեք ալիքին և իմացեք ավելին",
	mustBeNoLens: "{0} դաշտը չպետք է լինի {1} նիշից պակաս",
	mustBeWithoutSpaces: "{0} դաշտը պետք է լինի առանց բացատների",
	notFoundLabel: "Էջը չի գտնվել...",
	postNotFoundLabel: "Գրառումը չի գտնվել...",
	postsLabel: "գրառումներ",
	previewChannelLabel: "Նախադիտել ալիքը",
	searchOnChannelLabel: "Որոնել ալիքում",
	subscribersLabel: "բաժանորդներ",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "Դեպի ալիք",
	viewOnlyAppLabel: "Դիտվում է միայն TeleGuard-ում"
}