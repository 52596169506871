export default {
	channelAuthorLabel: "Author",
	channelCategoryLabel: "Category",
	channelDescriptionLabel: "Description",
	channelIdLabel: "Channel ID",
	channelLanguageLabel: "Language",
	copiedLabel: "Copied",
	downloadAppLabel: "Download TeleGuard app",
	errorInternalError: "We could not get a response from the server and are already dealing with this problem. Try to log in later and repeat the request again",
	errorNotFound: "We could not find the channel with the specified ID. Check the correctness of the entered ID and try again",
	fieldMustBeRequired: "The {0} field must be filled",
	findChannelLabel: "Find",
	joinToLabel: "Subscribe to the channel and learn more",
	mustBeNoLens: "Field {0} must not be less than {1} characters",
	mustBeWithoutSpaces: "Field {0} must be without spaces",
	notFoundLabel: "Page not found...",
	postNotFoundLabel: "Post not found...",
	postsLabel: "posts",
	previewChannelLabel: "Preview channel",
	searchOnChannelLabel: "Search in channel",
	subscribersLabel: "subscribers",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "To channel",
	viewOnlyAppLabel: "Viewing only in TeleGuard"
}