import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SearchChannel',
    component: () => import(/* webpackChunkName: "group-channels" */'@/views/SearchChannel.vue')
  },
  {
    path: '/:channelId',
    name: 'SplashChannel',
    component: () => import(/* webpackChunkName: "group-channels" */'@/views/SplashChannel.vue')
  },
  {
    path: '/:channelId/details',
    name: 'ChannelDetails',
    component: () => import(/* webpackChunkName: "group-channels" */'@/views/ChannelDetails.vue')
  },
  {
    path: '/post/:postId',
    name: 'ChannelSinglePost',
    component: () => import(/* webpackChunkName: "group-channels" */'@/views/ChannelSinglePost.vue')
  },
  {
    path: '*',
    component: () => import('@/components/ui/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

export default router
