export default {
	channelAuthorLabel: "Författare",
	channelCategoryLabel: "Kategori",
	channelDescriptionLabel: "Beskrivning",
	channelIdLabel: "Kanal-ID",
	channelLanguageLabel: "Språk",
	copiedLabel: "Kopierad",
	downloadAppLabel: "Ladda ner TeleGuard-appen",
	errorInternalError: "Vi kunde inte få något svar från servern och har redan börjat hantera problemet. Försök att logga in senare och upprepa begäran igen.",
	errorNotFound: "Vi kunde inte hitta kanalen med det angivna ID:t. Kontrollera att det angivna ID:t är korrekt och försök igen.",
	fieldMustBeRequired: "Fältet {0} måste fyllas i.",
	findChannelLabel: "Hitta",
	joinToLabel: "Prenumerera på kanalen och lär dig mer",
	mustBeNoLens: "Fält {0} får inte vara mindre än {1} tecken.",
	mustBeWithoutSpaces: "Fält {0} måste vara utan mellanslag",
	notFoundLabel: "Sidan hittades inte...",
	postNotFoundLabel: "Inlägget hittades inte...",
	postsLabel: "inlägg",
	previewChannelLabel: "Förhandsgranska kanal",
	searchOnChannelLabel: "Sök i kanalen",
	subscribersLabel: "prenumeranter",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "Till kanalen",
	viewOnlyAppLabel: "Visning endast i TeleGuard"
}