export default {
	channelAuthorLabel: "Autor",
	channelCategoryLabel: "Categoria",
	channelDescriptionLabel: "Descrição",
	channelIdLabel: "Identificação do canal",
	channelLanguageLabel: "Idioma",
	copiedLabel: "Copiado",
	downloadAppLabel: "Descarregar aplicação TeleGuard",
	errorInternalError: "Não conseguimos obter uma resposta do servidor e já estamos a lidar com este problema. Tente entrar mais tarde e repita o pedido",
	errorNotFound: "Não foi possível encontrar o canal com a identificação especificada. Verifique a correcção do ID introduzido e tente novamente",
	fieldMustBeRequired: "O campo {0} deve ser preenchido",
	findChannelLabel: "Encontrar",
	joinToLabel: "Subscreva o canal e saiba mais",
	mustBeNoLens: "Campo {0} não deve ser inferior a caracteres {1}.",
	mustBeWithoutSpaces: "O campo {0} deve estar sem espaços",
	notFoundLabel: "Página não encontrada...",
	postNotFoundLabel: "Post não encontrado...",
	postsLabel: "postagens",
	previewChannelLabel: "Canal de pré-visualização",
	searchOnChannelLabel: "Pesquisa no canal",
	subscribersLabel: "assinantes",
	tgIdLabel: "ID TeleGuard",
	tgLabel: "TeleGuard",
	toChannelLabel: "Para o canal",
	viewOnlyAppLabel: "Ver apenas em TeleGuard"
}