import Vue from 'vue';
import Vuex from 'vuex';
import DataService from "@/backend/services/dataService";
import { StatusResponse } from "@/backend/api";
import { IMAGE_BASE64_PREFIX, MEDIA_FILE_TYPE, ON_PAGE_COUNT_POSTS } from "@/utils/constants";
import { getUrls, modifyMediaField } from "@/utils/functions";
import findIndex from "lodash/findIndex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        channel: {},
        lastChannelId: "",
        posts: [],
        channelPreFilterPosts: [],
        isLoadingPosts: false,
        pagePosts: 0
    },
    getters: {
        channel(state) {
            return state.channel;
        },
        lastId(state) {
            return state.lastChannelId;
        },
        isLoadingPosts(state) {
            return state.isLoadingPosts;
        },
        currentPage(state) {
            return state.pagePosts;
        },
        posts(state) {
            return state.posts;
        }
    },
    mutations: {
        SET_IS_LOADING_POSTS: (state, _isLoading) => {
            state.isLoadingPosts = _isLoading;
        },
        SET_LAST_CHANNEL_ID: (state, _lastChannelId) => {
            state.lastChannelId = _lastChannelId;
        },
        SET_CHANNEL: (state, _channel) => {
            _channel.base64Img = "";
            _channel.posts = [];
            state.channel = _channel;
        },
        LOAD_AVATAR_IMAGE: (state, _image) => {
            state.channel.base64Img = _image;
        },
        SET_CHANNEL_POSTS: (state, _posts) => {
            state.channel.posts = _posts;
        },
        ADD_CHANNEL_POSTS: (state, _posts) => {
            state.channel.posts = [...state.channel.posts, ..._posts];
        },
        SET_POSTS: (state, _posts) => {
            state.posts = _posts;
        },
        SET_CHANNEL_PREFILTER_POSTS: (state, _posts) => {
            state.channelPreFilterPosts = _posts;
        },
        SET_PAGE: (state, _page) => {
            state.pagePosts = _page;
        },
        SET_THUMBNAIL_MEDIA_FILE: (state, _data) => {
            let indPost = findIndex(state.posts, { id: _data.mediaItem.post.id });
            if (indPost > -1) {
                let post = state.posts[indPost];
                const indMediaItem = findIndex(post.mediaData, { id: _data.mediaItem.mediaItem.id });
                if (indMediaItem > -1) {
                    post.mediaData[indMediaItem].preview = _data.content;
                    Vue.set(state.posts, indPost, post);
                }
            }

            const indVisiblePost = findIndex(state.channel.posts, { id: _data.mediaItem.post.id });
            if (indVisiblePost > -1) {
                let post = state.channel.posts[indVisiblePost];
                const indVisibleMediaItem = findIndex(post.mediaData, { id: _data.mediaItem.mediaItem.id });
                if (indVisibleMediaItem > -1) {
                    post.mediaData[indVisibleMediaItem].preview = _data.content;
                    Vue.set(state.channel.posts, indVisiblePost, post);
                }
            }
        },
        SET_MAIN_MEDIA_FILE: (state, _data) => {
            let indPost = findIndex(state.posts, { id: _data.mediaItem.post.id });
            if (indPost > -1) {
                let post = state.posts[indPost];
                const indMediaItem = findIndex(post.mediaData, { id: _data.mediaItem.mediaItem.id });
                if (indMediaItem > -1) {
                    post.mediaData[indMediaItem].fileBase64 = _data.content;
                    Vue.set(state.posts, indPost, post);
                }
            }

            const indVisiblePost = findIndex(state.channel.posts, { id: _data.mediaItem.post.id });
            if (indVisiblePost > -1) {
                let post = state.channel.posts[indVisiblePost];
                const indVisibleMediaItem = findIndex(post.mediaData, { id: _data.mediaItem.mediaItem.id });
                if (indVisibleMediaItem > -1) {
                    post.mediaData[indVisibleMediaItem].fileBase64 = _data.content;
                    Vue.set(state.channel.posts, indVisiblePost, post);
                }
            }
        },
        SET_LOAD_MEDIA_IN_PROCESS: (state, _data) => {
            let indPost = findIndex(state.posts, { id: _data.mediaItem.post.id });
            if (indPost > -1) {
                let post = state.posts[indPost];
                const indMediaItem = findIndex(post.mediaData, { id: _data.mediaItem.mediaItem.id });
                if (indMediaItem > -1) {
                    post.mediaData[indMediaItem].isLoading = _data.state;
                    Vue.set(state.posts, indPost, post);
                }
            }

            const indVisiblePost = findIndex(state.channel.posts, { id: _data.mediaItem.post.id });
            if (indVisiblePost > -1) {
                let post = state.channel.posts[indVisiblePost];
                const indVisibleMediaItem = findIndex(post.mediaData, { id: _data.mediaItem.mediaItem.id });
                if (indVisibleMediaItem > -1) {
                    post.mediaData[indVisibleMediaItem].isLoading = _data.state;
                    Vue.set(state.channel.posts, indVisiblePost, post);
                }
            }
        }
    },
    actions: {
        setLastChannelId({ commit }, _lastChannelId) {
            commit("SET_LAST_CHANNEL_ID", _lastChannelId);
        },
        setChannel({ commit }, _channel) {
            commit("SET_CHANNEL", _channel);
        },
        loadAvatarImage({ commit }, _avatarRefId) {
            DataService.loadAvatarImage(_avatarRefId).then((response) => {
                const imgStr = response.responseStatus === StatusResponse.OK ? IMAGE_BASE64_PREFIX + "," + response.data : "";
                commit("LOAD_AVATAR_IMAGE", imgStr);
            }).catch(() => { })
        },
        loadMediaFile({ commit }, _postAndMediaInfo) {
            commit("SET_LOAD_MEDIA_IN_PROCESS", { mediaItem: _postAndMediaInfo, state: true });
            DataService.getThumbnailMediaFile(_postAndMediaInfo.mediaItem.id).then((response) => {
                if (response.responseStatus === StatusResponse.OK) {
                    const imgStr = IMAGE_BASE64_PREFIX + "," + response.data;
                    commit("SET_THUMBNAIL_MEDIA_FILE", { mediaItem: _postAndMediaInfo, content: imgStr });
                }
            }).catch(() => { })
                .finally(() => commit("SET_LOAD_MEDIA_IN_PROCESS", { mediaItem: _postAndMediaInfo, state: false }));

            if (_postAndMediaInfo.mediaItem.type === MEDIA_FILE_TYPE.IMAGE) {
                DataService.getMediaFile(_postAndMediaInfo.mediaItem.id).then((response) => {
                    if (response.responseStatus === StatusResponse.OK) {
                        const contentStr = IMAGE_BASE64_PREFIX + "," + response.data;
                        commit("SET_MAIN_MEDIA_FILE", { mediaItem: _postAndMediaInfo, content: contentStr });
                    }
                }).catch(() => { })
            }
        },
        getPosts({ commit, state, dispatch }) {
            return new Promise((resolve, reject) => {
                commit("SET_IS_LOADING_POSTS", true);
                DataService.getPosts(state.channel.id).then((data) => {
                    let posts = data.data;
                    posts.map((postItem) => {
                        postItem.urls = getUrls(postItem.content);
                        postItem.mediaData = modifyMediaField(postItem.media);
                        return postItem;
                    });
                    commit("SET_POSTS", posts)
                    dispatch("getChannelPostsChunk", state.pagePosts);
                    resolve(posts);
                }).catch(() => { reject() }).finally(() => commit("SET_IS_LOADING_POSTS", false))
            });

        },
        getPostById({ commit, dispatch }, postId) {
            return new Promise((resolve, reject) => {
                DataService.getPostById(postId).then((data) => {
                    let posts = data.data;
                    if (posts.length > 0) {
                        posts.map((postItem) => {
                            postItem.urls = getUrls(postItem.content);
                            postItem.mediaData = modifyMediaField(postItem.media);
                            return postItem;
                        });
                        commit("SET_POSTS", posts);
                        posts.map(postItem => {
                            dispatch("loadPostMedia", postItem);
                        });
                        resolve(posts[0]);
                    }else{
                        reject();
                    }
                }).catch(() => { reject() }).finally(() => commit("SET_IS_LOADING_POSTS", false))
            });

        },
        getChannelPostsChunk({ commit, state, dispatch }, _page) {
            const startIndex = _page === 0 ? 0 : _page * ON_PAGE_COUNT_POSTS;
            const endIndex = startIndex + ON_PAGE_COUNT_POSTS;

            let chunkedPosts = state.posts.slice(startIndex, endIndex);

            if (chunkedPosts.length > 0 && state.channelPreFilterPosts.length === 0) {
                commit("ADD_CHANNEL_POSTS", chunkedPosts);

                chunkedPosts.map(postItem => {
                    dispatch("loadPostMedia", postItem);
                });
                const page = state.pagePosts + 1;
                commit("SET_PAGE", page);
            }
        },
        loadPostMedia({ dispatch }, _postItem) {
            if (_postItem.mediaData.length > 0) {
                _postItem.mediaData.map((mediaFileInfo) => {
                    dispatch("loadMediaFile", { post: _postItem, mediaItem: mediaFileInfo });
                });
            }
        },
        filterPosts({ commit, state }, _searchString) {
            const _searchArray = _searchString.split(" ");
            _searchArray.map((item) => item.trim().toLowerCase());
            if (_searchArray.length > 0 && _searchString.length > 0) {
                if (state.channelPreFilterPosts.length === 0) {
                    commit("SET_CHANNEL_PREFILTER_POSTS", state.channel.posts);
                }
                let filteredPosts = state.posts.filter((post) => {
                    for (let searchItem of _searchArray) {
                        if (post.content.toLowerCase().includes(searchItem) ||
                            post.header.toLowerCase().includes(searchItem)
                        ) {
                            return post;
                        }
                    }
                });
                commit("SET_CHANNEL_POSTS", filteredPosts);
            } else {
                commit("SET_CHANNEL_POSTS", state.channelPreFilterPosts);
                commit("SET_CHANNEL_PREFILTER_POSTS", []);
            }
        },
        clearPosts({ commit }) {
            commit("SET_POSTS", []);
        }
    }
})