export default {
	channelAuthorLabel: "نویسنده",
	channelCategoryLabel: "دسته بندی",
	channelDescriptionLabel: "شرح",
	channelIdLabel: "آیدی کانال",
	channelLanguageLabel: "زبان",
	copiedLabel: "کپی شده است",
	downloadAppLabel: "برنامه TeleGuard را دانلود کنید",
	errorInternalError: "ما نتوانستیم پاسخی از سرور دریافت کنیم و در حال حاضر با این مشکل روبرو هستیم. سعی کنید بعدا وارد شوید و دوباره درخواست را تکرار کنید",
	errorNotFound: "ما نتوانستیم کانال با شناسه مشخص شده را پیدا کنیم. صحت شناسه وارد شده را بررسی کنید و دوباره امتحان کنید",
	fieldMustBeRequired: "فیلد {0} باید پر شود",
	findChannelLabel: "پیدا کردن",
	joinToLabel: "عضو کانال شوید و بیشتر بدانید",
	mustBeNoLens: "فیلد {0} نباید کمتر از {1} کاراکتر باشد",
	mustBeWithoutSpaces: "فیلد {0} باید بدون فاصله باشد",
	notFoundLabel: "صفحه یافت نشد...",
	postNotFoundLabel: "پست پیدا نشد...",
	postsLabel: "نوشته ها",
	previewChannelLabel: "پیش نمایش کانال",
	searchOnChannelLabel: "جستجو در کانال",
	subscribersLabel: "مشترکین",
	tgIdLabel: "آیدیِ TeleGuard",
	tgLabel: "TeleGuard",
	toChannelLabel: "به کانال",
	viewOnlyAppLabel: "مشاهده فقط در TeleGuard"
}