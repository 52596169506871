export default {
	channelAuthorLabel: "Автор",
	channelCategoryLabel: "Категория",
	channelDescriptionLabel: "Описание",
	channelIdLabel: "ID на канала",
	channelLanguageLabel: "Език",
	copiedLabel: "Копирано",
	downloadAppLabel: "Изтегляне на приложението TeleGuard",
	errorInternalError: "Не успяхме да получим отговор от сървъра и вече се занимаваме с този проблем. Опитайте се да влезете по-късно и повторете заявката отново",
	errorNotFound: "Не успяхме да намерим канала с посочения идентификатор. Проверете правилността на въведеното ID и опитайте отново",
	fieldMustBeRequired: "Полето {0} трябва да бъде попълнено",
	findChannelLabel: "Намерете",
	joinToLabel: "Абонирайте се за канала и научете повече",
	mustBeNoLens: "Полето {0} не трябва да е по-малко от {1} символа",
	mustBeWithoutSpaces: "Поле {0} трябва да е без интервали",
	notFoundLabel: "Страницата не е намерена...",
	postNotFoundLabel: "Постът не е открит...",
	postsLabel: "публикации",
	previewChannelLabel: "Канал за предварителен преглед",
	searchOnChannelLabel: "Търсене в канала",
	subscribersLabel: "абонати",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "Отидете на канала",
	viewOnlyAppLabel: "Преглед само в TeleGuard"
}