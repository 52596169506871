export default {
	channelAuthorLabel: "Автор",
	channelCategoryLabel: "Категорія",
	channelDescriptionLabel: "Опис",
	channelIdLabel: "ID каналу",
	channelLanguageLabel: "Мова",
	copiedLabel: "Скопійовано",
	downloadAppLabel: "Завантажте додаток TeleGuard",
	errorInternalError: "Нам не вдалося отримати відповідь від сервера і ми вже займаємося цією проблемою. Спробуйте увійти пізніше і повторіти запит ще раз",
	errorNotFound: "Нам не вдалося знайти канал зі вказаним ID. Перевірте правильність введеного ID та повторіть спробу",
	fieldMustBeRequired: "Поле {0} має бути заповнено",
	findChannelLabel: "Знайти",
	joinToLabel: "Підпишіться на канал і дізнайтеся більше",
	mustBeNoLens: "Поле {0} має містити не менше {1} символів",
	mustBeWithoutSpaces: "Поле {0} має бути без пробілів",
	notFoundLabel: "Сторінку не знайдено...",
	postNotFoundLabel: "Post not found...",
	postsLabel: "дописи",
	previewChannelLabel: "Подивитися канал",
	searchOnChannelLabel: "Пошук у каналі",
	subscribersLabel: "підписників",
	tgIdLabel: "TeleGuard ID",
	tgLabel: "TeleGuard",
	toChannelLabel: "To channel",
	viewOnlyAppLabel: "Перегляд тільки в TeleGuard"
}