export default {
	channelAuthorLabel: "Szerző",
	channelCategoryLabel: "Kategória",
	channelDescriptionLabel: "Leírás",
	channelIdLabel: "Csatorna azonosító",
	channelLanguageLabel: "Nyelv",
	copiedLabel: "Másolva",
	downloadAppLabel: "TeleGuard alkalmazás letöltése",
	errorInternalError: "Nem tudtunk választ kapni a szervertől, és már foglalkozunk ezzel a problémával. Próbáljon meg később bejelentkezni, és ismételje meg a kérést.",
	errorNotFound: "Nem találtuk a megadott azonosítóval rendelkező csatornát. Ellenőrizze a megadott azonosító helyességét, és próbálja meg újra.",
	fieldMustBeRequired: "A {0} mezőt ki kell tölteni",
	findChannelLabel: "Keresd meg a",
	joinToLabel: "Iratkozzon fel a csatornára és tudjon meg többet",
	mustBeNoLens: "A {0} mező nem lehet kevesebb, mint {1} karakter.",
	mustBeWithoutSpaces: "A {0} mezőnek szóközök nélkül kell lennie",
	notFoundLabel: "Az oldal nem található...",
	postNotFoundLabel: "A bejegyzés nem található...",
	postsLabel: "hozzászólások",
	previewChannelLabel: "Előnézeti csatorna",
	searchOnChannelLabel: "Keresés a csatornán",
	subscribersLabel: "előfizetők",
	tgIdLabel: "TeleGuard ID azonosító kód",
	tgLabel: "TeleGuard",
	toChannelLabel: "A csatornára",
	viewOnlyAppLabel: "Csak a TeleGuardban történő megtekintés"
}